<template>
  <div class="container">
    <h1 style="text-align: center; margin: 20px">{{ mybt }}</h1>
    <h2 style="text-align: center; margin: 20px">请登录</h2>

    <div class="myform">
      <el-form label-width="auto" label-color="white" :model="formData" style="max-width: 80%" class="demo-ruleForm">
        <el-form-item label="姓名">
          <el-input v-model="formData.xm" placeholder="请输入姓名"></el-input>
        </el-form-item>
        <el-form-item label="密码">
          <el-input v-model="formData.mm" type="number" placeholder="请输入密码" autocomplete="off" show-password></el-input>
        </el-form-item>
      </el-form>
    </div>
    <div></div>
    <div class="mybutton">
      <el-row class="mb-4">
        <el-button type="success" @click="toLogin" :loading="myloading">登录</el-button>
        <el-button type="warning" @click="toLogout" :loading="myloading">注销</el-button>
        <el-button type="info" @click="toHome">返回</el-button>
      </el-row>
    </div>
    <p style="color: rgb(136, 136, 136); font-size: 6px;margin-top: 25px;">2023-8-13</p>
  </div>
</template>
<script setup>
import myGtdatafun from "../../myapi/myGtdatafun.js";
import { ref, reactive, onMounted, nextTick } from "vue";
import { useRouter } from "vue-router";
import { ElMessage } from "element-plus";
const router = useRouter();
const mybt = ref(""); //标题
const myloading = ref(false);
const formData = ref({
  xm: "", //姓名
  mm: "", //密码
  token: "", //
  bmcb: "",
});
//dom挂载后
onMounted(() => {
  mybt.value = myGtdatafun.cn_XMMC;
  formData.value.xm = localStorage.getItem(myGtdatafun.XMMC + "xm");
  formData.value.mm = localStorage.getItem(myGtdatafun.XMMC + "mm");
});

const toHome = () => {
  router.push("/");
};
const toLogout = () => {
  myloading.value = false
  myGtdatafun.reloginRemoveyhtoken();
  formData.value.xm = "";
  formData.value.mm = "";
};
const toLogin = () => {
  console.log('response');
  myloading.value = true
  localStorage.setItem(myGtdatafun.XMMC + "mm", formData.value.mm);
  myGtdatafun
    .MygettableData("tosql_login.php", formData.value) //sql存储过程=XP_html_yh_login_logout
    .then(function (response) {
      myloading.value = false
      if (response[0].id === "-1" || response[0].id === "-2") {
        myGtdatafun.reloginRemoveyhtoken(); //注销登录删除token
        ElMessage({ message: '登录失败,请重新登录!', type: 'error', });
      }
      if (response[0].id === "-31") {
        localStorage.setItem(myGtdatafun.XMMC + "yhtoken", response[0].info);
        localStorage.setItem(myGtdatafun.XMMC + "xm", response[0].xm);
        localStorage.setItem(myGtdatafun.XMMC + "gx", "");
        localStorage.setItem(myGtdatafun.XMMC + "bmcb", "部门厂别");
        //保存权限
        var qxList = JSON.stringify(response);
        localStorage.setItem(myGtdatafun.XMMC + "qxList", qxList); //用localStorage存储数组到本地浏览器数据
        localStorage.setItem(myGtdatafun.XMMC + "gx", response[1].gx);
        
        router.push({
          path: "/",
        });
        nextTick();
        ElMessage({ message: '登录成功!', type: 'success', });

      }
      if (response[0].id === "-32") {
        localStorage.removeItem(myGtdatafun.XMMC + "yhtoken"); //删除已记录的token
        localStorage.removeItem(myGtdatafun.XMMC + "xm"); //删除已记录的token
        ElMessage({ message: '用户名称或密码错误不能登录。', type: 'error', });
      }
    })
    .catch(function (error) {
      console.log(error);
    });
};
</script>

<style>
.container {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding-top: 20px;

  background-color: rgba(0, 0, 0, 0.8);
  text-align: center;
  color: white;
}

.el-form-item__label {
  color: #fff;
}

.myform {
  display: flex;
  justify-content: center;
  margin: 20px;
}

.mybutton {
  display: flex;
  justify-content: center;
}
</style>
